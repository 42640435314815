import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

import { safeStorage } from '@utils/storage'
import { logAddToCart, logRecoveredCartFailure, logRecoveredCartSuccess, logRemoveFromCart } from '@utils/analytics'
import type { ShippingType } from '@services/client/checkout/useGetShippingMethods'
import { createCart } from '@services/client/cart/createCart'
import { getCurrentCart } from '@services/client/cart/getCurrentCart'
import { getCartByHash } from '@services/client/cart/getCartByHash'
import { toast } from 'react-toastify'
import { useAuthState } from './auth'
import type { RegisterForm } from 'app/connect/[vendor]/checkout/Checkout'

export interface CartItem {
  id: string
  image: string
  parentId: string
  title: string
  price: number
  quantity: number
  patient?: string
  answer?: string
  variation?: {
    attribute: string
    value: string
    label: string
  }[]
}

interface CheckoutStore {
  coupon: {
    code: string
    value: number
    type: string
  }
  setCoupon: (coupon: { code: string; value: number; type: string }) => void
  rated: string[]
  setRated: (orderHash: string) => void
  cart: CartItem[]
  recoveryCartHash: string
  cart_hash: string
  reorderHash: string | null
  getCartTotal: () => number
  hasSubscribtion: () => boolean
  shippingType: ShippingType
  shippingAddress: RegisterForm['shipping']
  billingAddress: RegisterForm['billing']
  billingSame: boolean
  email: string
  setShippingType: (shippingType: ShippingType) => void
  setShippingAddress: (shippingAddress: RegisterForm['shipping']) => void
  setBillingAddress: (billingAddress: RegisterForm['billing']) => void
  setBillingSame: (billingSame: boolean) => void
  setReorderHash: (reorderHash: string | null) => void
  addToCart: (cartItem: CartItem) => void
  removeFromCart: (cartItem: CartItem) => void
  syncCart: (id?: string) => void
  setEmail: (email: string) => void
  reset: () => void
}

const initialState: Pick<CheckoutStore, 'recoveryCartHash' | 'shippingType' | 'cart' | 'cart_hash' | 'shippingAddress' | 'billingAddress' | 'billingSame' | 'email' | 'coupon' | 'rated' | 'reorderHash'> = {
  shippingType: null,
  cart: [],
  cart_hash: null,
  recoveryCartHash: null,
  shippingAddress: null,
  billingAddress: null,
  billingSame: true,
  email: '',
  coupon: null,
  rated: [],
  reorderHash: null,
}

export const useCheckoutStore = create<CheckoutStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      setCoupon: coupon => set({ coupon }),
      getCartTotal: () => {
        return get().cart.reduce((a, b) => a + b.price, 0)
      },
      hasSubscribtion: () => {
        return get().cart.some(el =>
          el.variation?.some(v => v.attribute === 'pa_ep_frequency' && v.value !== 'one-time-purchase')
        )
      },
      setShippingType: (shippingType) => set({ shippingType }),
      setShippingAddress: (shippingAddress) => set({ shippingAddress }),
      setBillingAddress: (billingAddress) => set({ billingAddress }),
      setBillingSame: (billingSame) => set({ billingSame }),
      setReorderHash: (reorderHash: string | null) => set({ reorderHash }),
      addToCart: async (cartItem) => {
        logAddToCart(cartItem)
        const data = [...get().cart, cartItem]
        set({ cart: data })
        if (useAuthState.getState().isLogged) {
          const { cart_hash } = await createCart(data)
          set({
            cart_hash: cart_hash
          })
        }
      },
      removeFromCart: async cartItem => {
        logRemoveFromCart(cartItem)
        const i = get().cart.findIndex(item => item.id === cartItem.id)
        const data = get().cart.filter((item, index) => index !== i)
        set({ cart: data })
        if (useAuthState.getState().isLogged) {
          const { cart_hash } = await createCart(data)
          set({
            cart_hash: cart_hash
          })
        }
      },
      syncCart: async (id?: string) => {
        const localItems = get().cart

        try {
          // biome-ignore lint/style/noVar: <explanation>
          // biome-ignore lint/correctness/noInnerDeclarations: <explanation>
          var { items, cart_hash } = id
            ? await getCartByHash(id)
                .then(res => {
                  logRecoveredCartSuccess()
                  return res
                })
                .catch(() => {
                  logRecoveredCartFailure()
                  toast.error('Oops! Sorry there was an error loading your cart, please try again later.')
                  return { items: [], cart_hash: null }
                })
            : await getCurrentCart()
        } catch (e) {
          console.warn('Error syncing or getting cart', e)
          return
        }

        if (!cart_hash) return

        const allItems = [...localItems, ...items]
        const uniqueItems = allItems.filter(
          (item, index) => allItems.findIndex(i => JSON.stringify(i) === JSON.stringify(item)) === index
        )
        const isEqual = JSON.stringify(items) === JSON.stringify(uniqueItems)

        set({
          cart: uniqueItems,
          cart_hash: cart_hash
        })

        if (!!localItems.length && !isEqual && useAuthState.getState().isLogged) {
          const { cart_hash } = await createCart(uniqueItems)
          set({
            cart_hash: cart_hash
          })
        }
      },
      setEmail: email => set({ email }),
      setRated: orderHash => set({ rated: [...get().rated, orderHash] }),
      reset: () =>
        set({
          ...initialState,
          rated: get().rated
        })
    }),
    {
      name: 'checkout_state',
      storage: createJSONStorage(() => safeStorage),
      version: 2,
      skipHydration: true,
      partialize: state => ({
        ...state,
        recoveryCartHash: null,
        reorderHash: null
      })
    }
  )
)